import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
    EditMasjidStateInterface,
    MasjidStateInterface,
} from 'src/app/masjids/interfaces/masjid.state.interface'

export const masjidFeatureSelector =
    createFeatureSelector<MasjidStateInterface>('masjid')

export const masjidListSelector = createSelector(
    masjidFeatureSelector,
    (masjidState: MasjidStateInterface) => masjidState.masjidsList
)

export const masjidLoadingSelector = createSelector(
    masjidFeatureSelector,
    (masjidState: MasjidStateInterface) => masjidState.loading
)

export const userAdminMasjidListSelector = createSelector(
    masjidFeatureSelector,
    (masjidState: MasjidStateInterface) => masjidState.userAdminMasjid
)

export const masjidSelector = createSelector(
    masjidFeatureSelector,
    (masjidState: MasjidStateInterface) => masjidState.masjid
)

export const editMasjidFeatureSelector =
    createFeatureSelector<EditMasjidStateInterface>('editMasjid')

export const editMasjidDataSelector = createSelector(
    editMasjidFeatureSelector,
    (masjidState: EditMasjidStateInterface) => masjidState.masjidData
)

export const editMasjidUpdateMasjidSelector = createSelector(
    editMasjidFeatureSelector,
    (masjidState: EditMasjidStateInterface) => ({
        deleteFiles: masjidState.deleteFiles,
        uploadFiles: masjidState.uploadFiles,
        newLessons: masjidState.newLessons,
        updateLessons: masjidState.updateLessons,
        deleteLessons: masjidState.deleteLessons,
        masjidData: masjidState.masjidData,
    })
)

export const editMasjidIsSubmittingSaveSelector = createSelector(
    editMasjidFeatureSelector,
    (masjidState: EditMasjidStateInterface) => masjidState.isSubmittingSave
)
