import { Inject, Injectable } from '@angular/core'
import { tapResponse } from '@ngrx/operators'
import { AppwriteException, ID, Models, Query, Storage } from 'appwrite'
import { Observable, from, map, throwError, switchMap, of, forkJoin } from 'rxjs'
import {
    NewsInterface,
    convertNewsDocumentToInterface,
} from 'src/app/news/interfaces/news.interface'
import { environment } from 'src/environments/environment'
import { LogType } from '../interfaces/log.interface'
import { APPWRITE_SDK, AppwriteSdk } from './appwrite.provider'
import { LogService } from './log.service'
import Utils from './utils'
import { FileStorageService } from './file-storage.service'

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    constructor(
        @Inject(APPWRITE_SDK) private _appwrite: AppwriteSdk,
        private _logService: LogService,
        private _storageService: FileStorageService
    ) {}

    public createMasjidNews(news: NewsInterface): Observable<Models.Document> {
        return from(
            this._appwrite.databases.createDocument(
                environment.main_database_id,
                environment.masjid_news_collection_id,
                news.news_id!!,
                {
                    title: news.title,
                    description: news.description,
                    masjid_id: news.masjid_id,
                    image_id: news.image_id,
                    video_id: news.video_id,
                }
            )
        ).pipe(
            tapResponse(
                (document) => {
                    console.debug('masjid news document is saved!', document)
                    return document
                },
                (error: any) => {
                    return this.handleErrors(error)
                }
            )
        )
    }

    getMasjidNewsById(id: string): Observable<NewsInterface> {
        return from(
            this._appwrite.databases.getDocument(
                environment.main_database_id,
                environment.masjid_news_collection_id,
                id
            )
        ).pipe(
            map((res) => {
                const news = convertNewsDocumentToInterface(res)
                if (news.image_id) {
                    news.imageUrl = this.getImageUrl(news.image_id)
                }
                return news
            }),
            tapResponse(
                (document) => {
                    console.log(
                        'Document converted successfully with image URL:',
                        document
                    )
                    return document
                },
                (error: any) => {
                    return this.handleErrors(error)
                }
            )
        )
    }

    getLatestNews(): Observable<NewsInterface[]>
    getLatestNews(masjidId: string): Observable<NewsInterface[]>
    
    getLatestNews(masjidId?: string): Observable<NewsInterface[]> {
        const query = masjidId ? [Query.equal('masjid_id', masjidId)] : [];
        return from(
            this._appwrite.databases.listDocuments(
                environment.main_database_id,
                environment.masjid_news_collection_id,
                query
            )
        ).pipe(
            map((res) => {
                let list: NewsInterface[] = res.documents.map((element) => {
                    const newsItem = convertNewsDocumentToInterface(element)
                    if (newsItem.image_id) {
                        newsItem.imageUrl = this.getImageUrl(newsItem.image_id)
                    }
                    if (newsItem.video_id) {
                        newsItem.videoUrl = this.getVideoUrl(newsItem.video_id)
                    }
                    return newsItem
                })
                return list
            }),
            tapResponse(
                (documents) => {
                    console.log(
                        'Masjid News Retrieved:',
                        documents
                    )
                    return documents
                },
                (error: any) => {
                    return this.handleErrors(error)
                }
            )
        )
    }

    updateNewsData(news: NewsInterface): Observable<boolean> {
        return from(
            this._appwrite.databases.updateDocument(
                environment.main_database_id,
                environment.masjid_news_collection_id,
                news.news_id!!,
                {
                    title: news.title,
                    description: news.description,
                    image_id: news.image_id,
                    video_id: news.video_id,
                }
            )
        ).pipe(
            map((res) => {
                console.debug('masjid news document is updated!', document)
                return true
            }),
            tapResponse(
                (success) => {
                    return success
                },
                (error: any) => {
                    return this.handleErrors(error)
                }
            )
        )
    }
    /**
     * Generate an image URL from Appwrite storage.
     * @param imageId - The ID of the image file in the Appwrite storage bucket.
     * @returns A URL string for the image.
     */
    getImageUrl(imageId: string): string {
        try {
            const url = this._storageService.getFilePreviewUrl(
                environment.news_images_bucket,
                imageId
            )
            console.debug('Generated image URL:', url)
            return url
        } catch (error) {
            this.handleErrors(error)
            return ''
        }
    }

    /**
     * Generate a video URL from Appwrite storage.
     * @param videoId - The ID of the video file in the Appwrite storage bucket.
     * @returns A URL string for the video.
     */
    getVideoUrl(videoId: string): string {
        try {
            // Use getFileDownload for videos to ensure proper playback
            const url = this._storageService.getFilePreviewUrl(
                environment.news_videos_bucket,
                videoId,
                true // Specify that this is a video
            )
            console.debug('Generated video URL:', url)
            return url
        } catch (error) {
            this.handleErrors(error)
            return ''
        }
    }

    handleErrors(error: any): Observable<never> {
        let message: string
        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else message = Utils.formatException(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        console.error(message)
        return throwError(() => error)
    }

    public deleteNews(newsId: string): Observable<boolean> {
        // First get the news document to check for media files
        return from(
            this._appwrite.databases.getDocument(
                environment.main_database_id,
                environment.masjid_news_collection_id,
                newsId
            )
        ).pipe(
            switchMap((document) => {
                const news = convertNewsDocumentToInterface(document);
                const deleteOperations: Observable<boolean>[] = [];

                // If there's an image, add delete operation
                if (news.image_id) {
                    deleteOperations.push(
                        from(this._appwrite.storage.deleteFile(
                            environment.news_images_bucket,
                            news.image_id
                        )).pipe(
                            map(() => true),
                            tapResponse({
                                next: () => console.debug('News image deleted successfully'),
                                error: (error: any) => {
                                    // If file not found, continue with deletion
                                    if (error instanceof AppwriteException && error.code === 404) {
                                        console.debug('News image not found, continuing with deletion');
                                        return of(true);
                                    }
                                    return this.handleErrors(error);
                                }
                            })
                        )
                    );
                }

                // If there's a video, add delete operation
                if (news.video_id) {
                    deleteOperations.push(
                        from(this._appwrite.storage.deleteFile(
                            environment.news_videos_bucket,
                            news.video_id
                        )).pipe(
                            map(() => true),
                            tapResponse({
                                next: () => console.debug('News video deleted successfully'),
                                error: (error: any) => {
                                    // If file not found, continue with deletion
                                    if (error instanceof AppwriteException && error.code === 404) {
                                        console.debug('News video not found, continuing with deletion');
                                        return of(true);
                                    }
                                    return this.handleErrors(error);
                                }
                            })
                        )
                    );
                }

                // After media files are deleted (if any), delete the document
                return (deleteOperations.length > 0 ? forkJoin(deleteOperations).pipe(map(() => true)) : of(true)).pipe(
                    switchMap(() => 
                        from(this._appwrite.databases.deleteDocument(
                            environment.main_database_id,
                            environment.masjid_news_collection_id,
                            newsId
                        ))
                    ),
                    map(() => true),
                    tapResponse({
                        next: () => {
                            console.debug('News document deleted successfully');
                        },
                        error: (error: any) => this.handleErrors(error)
                    })
                );
            })
        );
    }
}
